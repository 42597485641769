export const useSetToken = (token: string, key?: string) => {
  const {
    public: { isElectron },
  } = useRuntimeConfig();

  if (isElectron) {
    localStorage.setItem(key || "token", token);
  } else {
    useCookie(key || "token").value = token;
  }
};
